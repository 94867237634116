import { Box } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import arrowBlue from '@/assets/icons/arrow_blue.svg';
import { SubTitle, Title } from '@/styles/styled';

const BoxTitle = ({ title = '', subTitle, sx, titleStyle, viewMore }) => {
  const { t } = useTranslation('home');
  return (
    <Box sx={{ ...sx }}>
      <Title variant="h2" sx={{ ...titleStyle, fontSize: { xs: 20, md: 34 } }}>
        {title}
      </Title>
      {subTitle && (
        <Box display="flex" alignItems="center">
          <SubTitle fontSize={{ xs: 14, md: 16 }}>{subTitle}</SubTitle>
          {viewMore && (
            <Box display="flex" ml={1}>
              <Link
                passHref
                href={viewMore}
                style={{
                  color: '#1C9AD6',
                  fontSize: 16,
                  fontFamily: 'Roboto, sans-serif',
                  textDecoration: 'underline',
                  textUnderlineOffset: '2px',
                }}
              >
                {t('view_more')}
              </Link>
              <Image
                style={{ marginLeft: '5px' }}
                src={arrowBlue}
                width={20}
                height={18}
                alt="more"
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

BoxTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default BoxTitle;
