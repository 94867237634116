import EastIcon from '@mui/icons-material/East';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Link from 'next/link';

import BoxTitle from '@/components/box-title';

const Trial = ({ data }) => {
  const theme = useTheme();
  const trialData = data?.[0]?.attributes || data;

  return (
    <Box>
      {!!trialData?.experiences?.length && (
        <Box py={5}>
          <BoxTitle title={trialData?.name} subTitle={trialData?.overview} />
          <Box
            sx={{
              background: { md: theme.palette.background.trial, xs: 'none' },
              marginBottom: '10px',
            }}
            mt={4}
            py={{ md: 2 }}
            px={{ md: 5, xs: 0 }}
          >
            <Grid container spacing={{ xs: 0, md: 5 }} py={{ xs: 2 }}>
              {trialData?.experiences?.map((item) => {
                return (
                  <Grid
                    key={item?.id}
                    item
                    xs={12}
                    md={4}
                    sx={{
                      background: { md: 'none', xs: theme.palette.background.trial },
                      marginBottom: { md: 'none', xs: '10px' },
                      padding: { md: 'none', xs: '20px' },
                      borderRadius: { md: 'none', xs: '5px' },
                    }}
                  >
                    <Link href={item?.hyperlink}>
                      <Box
                        sx={{
                          minHeight: '60px',
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                          cursor: 'pointer',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          '&:hover p': {
                            color: theme.palette.highlight,
                          },

                          '&:hover svg': {
                            color: theme.palette.highlight,
                            transform: 'translateX(20px)',
                          },
                        }}
                      >
                        <Typography
                          mr={3}
                          fontSize={{ xs: 16, md: 20 }}
                          fontWeight={700}
                          color={theme.palette.text.white}
                          fontFamily="SVN-Gotham"
                        >
                          {item?.title}
                        </Typography>
                        <EastIcon
                          sx={{
                            color: 'white',
                            transition: '1s ease-in-out',
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          fontSize={14}
                          lineHeight="22px"
                          color={theme.palette.text.white}
                        >
                          {item?.content}
                        </Typography>
                      </Box>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Trial;
