import React from 'react';

import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';

import BoxTitle from '@/components/box-title';
import { ContentBox, SectionWrapper, Title } from '@/styles/styled';

const CustomersStory = ({ data }) => {
  const theme = useTheme();
  return (
    <Box py={5}>
      <SectionWrapper>
        <BoxTitle
          title={data?.[0]?.attributes?.title}
          subTitle={data?.[0]?.attributes?.overview}
          // viewMore="/cau-chuyen-thanh-cong"
        />
        <Grid container spacing={4} mt={4}>
          {data?.[0]?.attributes?.detail?.map((item, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Box
                sx={{
                  '&:hover': {
                    color: theme.palette.highlight,
                  },
                }}
              >
                <Link href={`${item?.hyperlink}`} passHref>
                  <Image
                    style={{ width: '100%', height: 'auto' }}
                    width={0}
                    height={0}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
                    src={item?.previewImage?.url}
                    alt={item?.title}
                  />
                  <Title color="inherit" mt={2} variant="h3" fontSize={18} fontWeight={700}>
                    {item?.title}
                  </Title>
                  <ContentBox variant="p" mt={1}>
                    {item?.previewContent}
                  </ContentBox>
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </SectionWrapper>
    </Box>
  );
};

export default CustomersStory;
