import { useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { SectionWrapper, Title } from '@/styles/styled';

const WhyCMC = ({ data }) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(1);

  const onActiveTab = (id) => {
    setActiveTab(id);
  };

  return (
    <SectionWrapper sx={{ py: 5 }}>
      <Box mb={4}>
        <Title variant="h2" fontSize={{ xs: 20, md: 34 }} fontWeight={900}>
          {data?.[0]?.attributes?.title}
        </Title>

        <Typography>{data?.[0]?.attributes?.overview}</Typography>
      </Box>
      <Grid container spacing={15}>
        <Grid item md={4}>
          {data?.[0]?.attributes?.reasons?.map((item) => (
            <Box
              position="relative"
              key={item?.id}
              p={2}
              sx={{
                cursor: 'pointer',
                '&:hover .why-title': {
                  color: theme.palette.highlight,
                },
              }}
              borderBottom={`1px solid ${theme.palette.text.tertiary}`}
              onClick={() => {
                onActiveTab(item?.id);
              }}
            >
              <Typography
                fontSize={16}
                fontWeight={500}
                className="why-title"
                sx={{
                  color:
                    activeTab === item?.id ? theme.palette.highlight : theme.palette.text.secondary,
                }}
                variant="h3"
              >
                {item?.title}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{
                  color: theme.palette.text.tertiary,
                }}
              >
                {item?.previewContent}
              </Typography>
              <ChevronRightIcon
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: '25px',
                  color: theme.palette.text.tertiary,
                }}
              />
            </Box>
          ))}
        </Grid>
        <Grid item md={8}>
          {data?.[0]?.attributes?.reasons?.map((item) =>
            item?.id === activeTab ? (
              <Box
                key={item?.id}
                mt={2}
                className="ck-content"
                dangerouslySetInnerHTML={{
                  __html: item?.content,
                }}
              />
            ) : null
          )}
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default WhyCMC;
