import { useEffect, useState } from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import { Autoplay, FreeMode, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import useResponsive from '@/hooks/useResponsive';
import { ContentLimitLineStyled, SectionWrapper } from '@/styles/styled';
import { getMaxHeightElements } from '@/utils';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const ThumbsSlide = ({ dataBanner, widgets }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [heightBanner, setHeightBanner] = useState(0);

  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');
  useEffect(() => {
    const bannerContentList = document.getElementsByClassName('banner-content');
    const mySwiper = document.getElementById('mySwiper');
    const widgetsContainerHeight = document.getElementById('widgets-container').offsetHeight;
    const maxHeightBannerContent = getMaxHeightElements(bannerContentList);

    setHeightBanner(maxHeightBannerContent + widgetsContainerHeight + 200);
    const windowWidth = window.innerWidth;
    if (windowWidth < 390) {
      mySwiper.style.width = '256px';
    }
  }, [widgets]);

  return (
    <Box position="relative">
      <Box
        sx={{
          '& .swiper-thumbs': {
            bottom: { xs: '325px', md: '220px' },
            width: { xs: '350px', md: '400px' },
            '& .swiper-wrapper': {
              justifyContent: 'center',
              '& .swiper-slide': {
                opacity: 0.7,
                height: 49,
                borderBottom: '3px solid transparent',
                transition: 'all 0.2 ease-in-out',
                '&:hover': {
                  cursor: 'pointer',
                },
              },
              '& .swiper-slide.swiper-slide-thumb-active': {
                opacity: 1,
                borderBottom: '3px solid #0082D6',
              },
            },
          },
        }}
      >
        <Swiper
          loop
          style={{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          }}
          autoplay={{ delay: 7000, disableOnInteraction: false }}
          thumbs={{
            swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          slidesPerView={1}
          modules={[FreeMode, Thumbs, Autoplay]}
          className="mySwiper2"
        >
          {dataBanner?.map((image) => (
            <SwiperSlide key={image?.id}>
              <Image
                width={0}
                height={0}
                style={{
                  width: '100%',
                  objectFit: 'cover',
                  height: isDesktop ? heightBanner : heightBanner - 50,
                  '@media (maxWidth: 992px)': {
                    height: '800px',
                  },
                }}
                sizes="100vw"
                alt="banner"
                data-src={image?.attributes?.image?.data?.attributes?.url}
                src={image?.attributes?.image?.data?.attributes?.url}
              />
              <Box
                sx={{
                  background: theme.palette.text.blue,
                  position: 'absolute',
                  height: '100%',
                  width: { xs: '100%', md: '50%' },
                  opacity: 0.5,
                  top: 0,
                  left: 0,
                }}
              />
              <SectionWrapper>
                <Box
                  className="banner-content"
                  sx={{
                    position: 'absolute',
                    top: { xs: '5%', md: '15%' },
                    opacity: 1,
                    p: { xs: 2, md: 0 },
                    width: { md: '34vw' },
                  }}
                >
                  <Typography
                    fontFamily="SVN-Gotham, sans-serif"
                    fontSize={34}
                    fontWeight={900}
                    lineHeight="44px"
                    mb={3}
                    color={theme.palette.text.white}
                    variant="h1"
                  >
                    {image?.attributes?.title}
                  </Typography>
                  <ContentLimitLineStyled
                    lineHeight="30px"
                    color={theme.palette.text.white}
                    fontSize={18}
                  >
                    {image?.attributes?.content}
                  </ContentLimitLineStyled>
                  {image?.attributes?.buttons?.map((item) => {
                    return (
                      <Link key={item?.id} href={item?.url}>
                        <Button
                          variant="outlined"
                          fullWidth={!isDesktop}
                          sx={{
                            mt: 3,
                            mr: 2,
                            color: theme.palette.text.white,
                            border: '1px solid white',
                            textTransform: 'capitalize',
                          }}
                        >
                          {item?.name}
                        </Button>
                      </Link>
                    );
                  })}
                </Box>
              </SectionWrapper>
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          loop
          onSwiper={setThumbsSwiper}
          slidesPerView={4}
          freeMode
          watchSlidesProgress
          modules={[FreeMode, Thumbs, Autoplay]}
          className="mySwiper"
          id="mySwiper"
          spaceBetween={10}
        >
          {dataBanner?.map((image) => (
            <SwiperSlide key={image?.id} className="swiper-slide-auto">
              <Image
                alt="thumbnail"
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={image?.attributes?.image?.data?.attributes?.url}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <SectionWrapper
        sx={{
          position: 'absolute',
          bottom: '70px',
          '@media (max-width: 1280px)': {
            transform: 'translate(-50%)',
            left: '50%',
          },
          zIndex: 10,
          p: { xs: 2, md: 0 },
        }}
      >
        <Box
          id="widgets-container"
          sx={{
            margin: '0 16px !important',
            background: { xs: 'none', md: 'linear-gradient(to right,#96D3FFCC, #FFFFFF80)' },
            backdropFilter: { xs: 'none', md: 'blur(5px)' },
            borderRadius: '6px',
          }}
        >
          <Grid container spacing={{ xs: 2, md: 0 }}>
            {widgets.map((item) => (
              <Grid xs={6} md={3} item key={`thumbnail-${item?.id}`} flex={1}>
                <Box
                  sx={{
                    height: '100%',
                    borderRadius: { xs: '6px', md: 0 },
                    p: { xs: 2, md: 3 },
                    background: {
                      md: 'none',
                      xs: 'linear-gradient(to right,#96D3FFCC, #FFFFFF80)',
                    },
                    backdropFilter: { xs: 'none', md: 'blur(5px)' },
                    '&:hover': {
                      background: 'linear-gradient(to right,#96D3FFCC, #FFFFFF80)',
                      borderRadius: '6px',
                    },
                    '&:hover .arrow-banner-loop': {
                      transform: 'translateX(20px)',
                    },
                  }}
                >
                  <Link
                    href={item?.hyperlink}
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      mr={2}
                      fontSize={{ xs: 18, md: 22 }}
                      fontWeight={700}
                      color={theme.palette.text.secondary}
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                    >
                      {item?.title}
                    </Typography>
                    <ArrowRightAltIcon
                      className="arrow-banner-loop"
                      sx={{
                        transition: '1s ease-in-out',
                      }}
                    />
                  </Link>
                  <Typography
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                    }}
                    display={isDesktop ? 'block' : 'none'}
                  >
                    {item?.content}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </SectionWrapper>
    </Box>
  );
};

export default ThumbsSlide;
