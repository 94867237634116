/* eslint-disable max-len */
import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Helmet } from 'react-helmet';

import clock from '@/assets/icons/clock.svg';
import BoxTitle from '@/components/box-title';
import SimpleSlider from '@/components/button-tab-slider';
import ContentLimitedLine from '@/components/content-limit-line';
import CustomersStory from '@/components/customers-story';
import Infrastructure from '@/components/infrastructure';
import Page from '@/components/page';
import Partner from '@/components/partner-slide';
import ProductsHome from '@/components/products-home';
import ThumbsSlide from '@/components/thumbs-slide';
import Trial from '@/components/trial';
import WhyCMC from '@/components/why-cmc';
import { DATE_FORMAT } from '@/constants';
import useResponsive from '@/hooks/useResponsive';
import {
  getBannerPage,
  getCustomers,
  getExperiences,
  getHomepageService,
  getNewsHomePage,
  getPrizes,
  getReasons,
  getSolution,
  getStories,
  getStrengths,
} from '@/services/apis';
import { ContentBox, SectionWrapper, Title } from '@/styles/styled';

import 'swiper/css';
import 'swiper/css/autoplay';

const Home = ({
  pageBanner,
  solutions,
  reasons,
  strengths,
  prizes,
  experiences,
  customers,
  stories,
  services,
  news,
}) => {
  const { t } = useTranslation(['home', 'common']);
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState(solutions[0]?.attributes?.detail?.[0] || []);
  const [selectedCustomerIndustry, setSelectedCustomerIndustry] = useState(
    customers[0]?.attributes?.categories?.[0] || []
  );

  const isDesktop = useResponsive('up', 'sm');
  return (
    <Page title={t('page_title')} metaDescription={t('meta_description')}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: 'CMC Cloud',
            url: 'https://cmccloud.vn',
            description:
              'CMC Cloud nhà cung cấp dịch vụ điện toán đám mây hàng đầu, khởi tạo Cloud Server nhanh chóng, không gian lưu trữ dữ liệu không giới hạn. Với lợi thế về hạ tầng số, đường truyền và Data Center, CMC Cloud đảm bảo an toàn bảo mật, tối ưu vận hành cho doanh nghiệp.',
            telephone: '19002010',
            email: 'support@cmccloud.vn',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Tòa nhà CMC, 11 P. Duy Tân',
              addressLocality: 'Dịch Vọng Hậu, Cầu Giấy',
              addressRegion: 'Hà Nội',
              addressCountry: 'Việt Nam',
            },
            geo: {
              '@type': 'GeoCoordinates',
              latitude: 21.030487806415042,
              longitude: 105.78436080679676,
            },
            openingHoursSpecification: {
              '@type': 'OpeningHoursSpecification',
              dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
              opens: '08:00',
              closes: '18:00',
            },
            image: 'URL_TO_YOUR_IMAGE',
            sameAs: [
              'https://facebook.com/cmccloud.vn',
              'https://zalo.me/cmccloud',
              'https://linkedin.com/company/cmc-cloud',
              'https://www.youtube.com/@CMCCloudOfficial',
            ],
          })}
        </script>
      </Helmet>
      <Box>
        <ThumbsSlide dataBanner={pageBanner?.data} widgets={pageBanner?.widgets} />
      </Box>
      <WhyCMC data={reasons} />
      {isDesktop && <ProductsHome data={services} />}
      <Box sx={{ bgcolor: theme.palette.default }}>
        <SectionWrapper sx={{ py: { md: 5, xs: 3 } }}>
          <BoxTitle
            sx={{ mb: 5 }}
            title={solutions[0]?.attributes?.title}
            subTitle={solutions[0]?.attributes?.overview}
          />
          {solutions && (
            <>
              <SimpleSlider
                data={solutions[0]?.attributes?.detail}
                selectedItem={selectedItem}
                changeSelectedItem={(item) => setSelectedItem(item)}
                limitTitleLength={20}
                maxSlidesPerView={4}
              />

              <Grid container spacing={2}>
                {selectedItem?.solutions?.map((item, index) => (
                  <Grid item xs={12} mb={4} md={3} key={index}>
                    <Box
                      sx={{
                        '&:hover': {
                          color: theme.palette.highlight,
                        },
                      }}
                    >
                      <Link href={`/giai-phap/${item?.slug}`} passHref>
                        <Image
                          style={{ width: '100%', height: 'auto' }}
                          width={0}
                          height={0}
                          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
                          src={item?.previewImage?.url}
                          alt={item?.title}
                        />
                        <Title color="inherit" mt={2} variant="h3" fontSize={18} fontWeight={700}>
                          {item?.title}
                        </Title>
                        <ContentBox mt={1}>{item?.previewContent}</ContentBox>
                      </Link>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </SectionWrapper>
      </Box>
      {/* Câu chuyện khách hàng */}
      <Box sx={{ bgcolor: theme.palette.background.card }}>
        <CustomersStory data={stories} />
      </Box>
      {/* Thế mạnh cơ sở hạ tầng */}
      <Infrastructure data={strengths} />
      {/* Giải thưởng */}
      <SectionWrapper py={5}>
        <Partner
          items={prizes?.[0]?.attributes?.prize}
          title={prizes?.[0]?.attributes?.name}
          description={prizes?.[0]?.attributes?.overview}
        />
      </SectionWrapper>
      {/* Khách hàng tiêu biểu */}
      <SectionWrapper sx={{ py: { md: 5, xs: 3 } }}>
        <BoxTitle
          sx={{ mb: 5 }}
          title={customers[0]?.attributes?.name}
          subTitle={customers[0]?.attributes?.overview}
        />
        {customers && (
          <>
            <SimpleSlider
              data={customers[0]?.attributes?.categories}
              selectedItem={selectedCustomerIndustry}
              changeSelectedItem={(item) => setSelectedCustomerIndustry(item)}
              limitTitleLength={20}
              maxSlidesPerView={4}
            />

            <Grid container alignItems="center">
              {selectedCustomerIndustry?.customers?.map((item, index) => (
                <Grid
                  item
                  py={5}
                  xs={12 / 3}
                  md={12 / 8}
                  key={index}
                  sx={{
                    img: {
                      '@media (max-width: 400px)': {
                        minWidth: '70px',
                        margin: 'auto',
                      },
                    },
                  }}
                >
                  <Image
                    style={{
                      objectFit: 'cover',
                    }}
                    width={100}
                    height={100}
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
                    src={item?.image?.data?.attributes?.url}
                    alt={item?.name}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </SectionWrapper>
      {/* Tin tức */}
      <Box py={5} bgcolor={theme.palette.background.card}>
        <SectionWrapper>
          <BoxTitle
            sx={{ mb: 5 }}
            title={news?.[0]?.attributes?.title}
            subTitle={news?.[0]?.attributes?.overview}
            // viewMore="/cloud-blog"
          />
          {news?.[0]?.attributes?.news?.data?.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Link
                  passHref
                  href={`/${news?.[0]?.attributes?.slug}/${news?.[0]?.attributes?.news?.data?.[0]?.attributes?.slug}`}
                >
                  <Box>
                    <Image
                      width={0}
                      height={0}
                      sizes="100vw"
                      style={{ width: '100%', height: 'auto' }}
                      src={
                        news?.[0]?.attributes?.news?.data?.[0]?.attributes?.previewImage?.data
                          ?.attributes?.url
                      }
                      alt={news?.[0]?.attributes?.news?.data?.[0]?.attributes?.title}
                    />
                  </Box>
                  <Box mt={2}>
                    <ContentLimitedLine
                      title={news?.[0]?.attributes?.news?.data?.[0]?.attributes?.title || ''}
                      sx={{
                        color: theme.palette.text.blue,
                        fontFamily: 'SVN-Gotham, sans-serif',
                        fontWeight: 700,
                        fontSize: 18,
                        '&:hover': {
                          color: theme.palette.highlight,
                        },
                      }}
                      color={theme.palette.text.blue}
                      fontFamily="SVN-Gotham, sans-serif"
                      fontSize={18}
                      fontWeight={700}
                      mb={1}
                    />
                    <Box display={{ xs: 'none', md: 'flex' }} gap={1} mb={1}>
                      <Image width={24} height={24} src={clock} alt="updateTime" />
                      <Typography color={theme.palette.text.blue} fontSize={14} fontWeight={300}>
                        {news?.[0]?.attributes?.news?.data?.[0]?.attributes?.updatedAt
                          ? moment(
                              news?.[0]?.attributes?.news?.data?.[0]?.attributes?.updatedAt
                            ).format(DATE_FORMAT)
                          : moment().format(DATE_FORMAT)}
                      </Typography>
                    </Box>

                    <ContentLimitedLine
                      title={news?.[0]?.attributes?.news?.data?.[0]?.attributes?.previewContent}
                      sx={{ fontSize: 14, display: { xs: 'none', md: 'flex' } }}
                    />
                  </Box>
                </Link>
              </Grid>
              <Grid item xs={12} md={6}>
                {news?.[0]?.attributes?.news?.data?.slice(1, 5).map((item) => (
                  <Link
                    key={item?.id}
                    passHref
                    href={`/${news?.[0]?.attributes?.slug}/${item?.attributes?.slug}`}
                  >
                    <Grid container spacing={2} mb={2}>
                      <Grid item xs={4} md={4}>
                        <Image
                          width={0}
                          height={0}
                          sizes="100vw"
                          style={{ width: '100%', height: 'auto' }}
                          src={item?.attributes?.previewImage?.data?.attributes?.url}
                          alt="news"
                        />
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <ContentLimitedLine
                          title={item?.attributes?.title}
                          sx={{
                            fontSize: { xs: 14, md: 18 },
                            fontWeight: 700,
                            fontFamily: 'SVN-Gotham, sans-serif',
                            mb: 1,
                            color: theme.palette.text.blue,
                            '&:hover': {
                              color: theme.palette.highlight,
                            },
                          }}
                        />

                        <Box display={{ xs: 'none', md: 'flex' }} gap={1}>
                          <Image width={24} height={24} src={clock} alt="updateTime" />

                          <Typography
                            color={theme.palette.text.blue}
                            fontSize={14}
                            fontWeight={300}
                          >
                            {item?.attributes?.updatedAt
                              ? moment(item?.attributes?.updatedAt).format(DATE_FORMAT)
                              : moment().format(DATE_FORMAT)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Link>
                ))}
              </Grid>
            </Grid>
          )}
        </SectionWrapper>
      </Box>
      <SectionWrapper>
        <Trial data={experiences} />
      </SectionWrapper>
    </Page>
  );
};

export const getServerSideProps = async ({ locale }) => {
  try {
    const pageBanner = await getBannerPage({ type: 'homepage', locale });
    const strengths = await getStrengths(locale);
    const prizes = await getPrizes(locale);
    const solutions = await getSolution(locale);
    const experiences = await getExperiences(locale);
    const customers = await getCustomers(locale);
    const stories = await getStories(locale);
    const reasons = await getReasons(locale);
    const services = await getHomepageService(locale);
    const news = await getNewsHomePage(locale);

    return {
      props: {
        ...(await serverSideTranslations(locale, [
          'header',
          'footer',
          'common',
          'home',
          'pop-up',
          'news',
        ])),
        pageBanner,
        reasons,
        solutions,
        strengths,
        prizes,
        experiences,
        customers,
        stories,
        services,
        news,
      },
    };
  } catch (error) {
    return { notFound: true };
  }
};

export default Home;
