/* eslint-disable react/display-name */
import { forwardRef, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { i18n } from 'next-i18next';
import { NextSeo } from 'next-seo';

import palette from '@/theme/palette';

const Page = forwardRef(
  (
    {
      children,
      title = '',
      meta,
      error,
      metaDescription,
      ogImage = `${
        typeof window !== 'undefined' && window.location.origin ? window.location.origin : ''
      }/images/thumbnail.png`,
      ogImageAlt = 'CMC Cloud',
      ...other
    },
    ref
  ) => {
    const [canonical, setCanonical] = useState('');
    const [robotsContent, setRobotsContent] = useState('index,follow');
    useEffect(() => {
      if (typeof window !== 'undefined') {
        setCanonical(window.location.origin + window.location.pathname);
        setRobotsContent(
          error ||
            i18n.language === 'en' ||
            window.location.pathname === '/event' ||
            window.location.pathname === '/case-study'
            ? 'noindex'
            : 'index,follow'
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, i18n.language]);
    return (
      <>
        <NextSeo
          title={title}
          additionalMetaTags={[
            {
              name: 'viewport',
              content:
                'width=device-width, initial-scale=1, maximum-scale=5, shrink-to-fit=no, user-scalable=no',
            },
            {
              name: 'robots',
              content: robotsContent,
            },
          ]}
          themeColor={palette.dark.primary.main}
          description={metaDescription}
          canonical={canonical}
          openGraph={{
            type: 'website',
            url: `${
              typeof window !== 'undefined' && window.location.origin ? window.location.origin : ''
            }${
              typeof window !== 'undefined' && window.location.pathname
                ? window.location.pathname
                : ''
            }`,
            title,
            description: metaDescription,
            images: [
              {
                url: ogImage,
                alt: ogImageAlt,
              },
            ],
            site_name: 'CMC Cloud',
          }}
        />

        <Box ref={ref} {...other}>
          {children}
        </Box>
      </>
    );
  }
);

export default Page;
