import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import useResponsive from '@/hooks/useResponsive';
import { Title } from '@/styles/styled';

const Partner = ({ items, title, description, screen }) => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');

  const settings = {
    infinite: true,
    speed: 1500,
    autoplay: true,
    slidesToShow: Math.min(6, items?.length), // Limit to the number of items available
    dots: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const renderItemSlide = (data) => {
    return data?.map((item, index) => (
      <Grid item xs={12} key={index}>
        <Box
          height={240}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            loading="eager"
            width={200}
            height={200}
            src={
              item.image?.data?.attributes?.url ||
              item.attributes?.image?.data?.attributes?.url ||
              item.image?.data?.[0]?.attributes?.url
            }
            alt={item.name || 'img'}
          />
        </Box>
        {item?.name && screen === 'event-detail' && (
          <Typography
            textAlign="center"
            fontSize={26}
            color={theme.palette.text.about_text}
            fontWeight={700}
            fontFamily="SVN-Gotham"
          >
            {item?.name}
          </Typography>
        )}
      </Grid>
    ));
  };

  return (
    <Box>
      <Title
        sx={{
          color: theme.palette.text.title_aboutUs,
          fontSize: { md: 34, xs: 20 },
          fontWeight: 900,
        }}
        variant="h2"
      >
        {title}
      </Title>
      <Typography>{description}</Typography>
      <Box py={5}>
        {screen === 'event-detail' && !isDesktop ? (
          <Grid container>{renderItemSlide(items)}</Grid>
        ) : (
          <Slider {...settings}>{renderItemSlide(items)}</Slider>
        )}
      </Box>
    </Box>
  );
};

export default Partner;
