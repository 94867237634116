import { Box, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useResponsive from '@/hooks/useResponsive';

export default function SimpleSlider({ data, selectedItem, changeSelectedItem }) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');

  const maxSlidesPerView = isDesktop ? 7 : 2;
  return (
    <Box my={2}>
      <Tabs
        value={data?.findIndex((obj) => {
          return obj.id === selectedItem?.id;
        })}
        onChange={(e, value) => {
          changeSelectedItem(data[value]);
        }}
        variant={data?.length > maxSlidesPerView ? 'scrollable' : 'standard'}
        scrollButtons
        allowScrollButtonsMobile
        className="tabs-scroll"
        aria-label="scrollable force tabs"
        sx={{
          alignItems: 'center',
          '&.tabs-scroll .Mui-selected': {
            color: '#fff',
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        {data?.map((item) => {
          const itemSelected = item?.id === selectedItem?.id;
          return (
            <Tab
              sx={{
                minWidth: '12%',
                padding: { xs: '8px 5px' },
                marginRight: '20px',
                minHeight: '30px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: 500,
                fontSize: 13,
                borderRadius: '3px',
                background: itemSelected ? `${theme.palette.highlight} !important` : 'inherit',
                boxShadow: !itemSelected
                  ? '0px 6.3716139793396px 9.55742073059082px 0px rgba(16, 17, 19, 0.10)'
                  : 'inherit',
                border: !itemSelected
                  ? `1px solid ${theme.palette.border.tab}`
                  : '1px solid transparent',
                color: itemSelected ? theme.palette.text.white : theme.palette.text.default,
                '&:hover': {
                  color: theme.palette.text.white,
                  background: theme.palette.background.primary,
                },
                textTransform: 'inherit',
              }}
              key={`banner-${item?.id}`}
              label={item?.name || item?.attributes?.groupName}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
