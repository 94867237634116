import { Box, Grid, Typography } from '@mui/material';
import Image from 'next/image';

import BoxTitle from '@/components/box-title';
// import GlobeComponent from '@/components/globe';
import { SectionWrapper, Title } from '@/styles/styled';

const Infrastructure = ({ data }) => {
  return (
    <Box position="relative">
      <SectionWrapper py={5} minHeight={500}>
        <Grid container>
          <Grid item xs={12} md={7} order={{ xs: 1, md: 0 }}>
            <BoxTitle
              sx={{ mb: 4 }}
              title={data[0]?.attributes?.title}
              subTitle={data[0]?.attributes?.overview}
            />
            {data[0]?.attributes?.strength?.map((item) => (
              <Grid container spacing={1} key={item?.id} mb={4} display="flex">
                <Grid item md={3}>
                  <Image
                    width={0}
                    height={0}
                    sizes="100vw"
                    style={{ width: '176px', height: '77px', objectFit: 'cover' }}
                    src={item?.image?.data?.attributes?.url || ''}
                    alt="cloud"
                  />
                </Grid>
                <Grid item md={9}>
                  <Box>
                    <Title color="inherit" fontSize={18} fontWeight={700} mb={0.5} variant="h3">
                      {item?.title}
                    </Title>
                    <Typography>{item?.content}</Typography>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} md={5}>
            {/* <Box
              sx={{
                position: { xs: 'inherit', md: 'absolute' },
                top: '-130px',
                right: { xs: 'inherit', md: 0 },
              }}
            >
              <GlobeComponent />
            </Box> */}
            <Box>
              <Image
                style={{ width: '100%', height: 'auto' }}
                src={data[0]?.attributes?.banner?.data?.attributes?.url || ''}
                width={0}
                sizes="100vw"
                height={0}
                alt={data[0]?.attributes?.banner?.data?.attributes?.name || ''}
              />
            </Box>
          </Grid>
        </Grid>
      </SectionWrapper>
    </Box>
  );
};

export default Infrastructure;
