import { Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ContentLimitLineStyled } from '@/styles/styled';

const ContentLimitedLine = ({ title, sx, className }) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={<Typography sx={{ color: theme.palette.text.dark }}>{title}</Typography>}
      placement="top"
      componentsProps={{
        tooltip: {
          style: {
            background: theme.palette.background.dark,
          },
        },
      }}
    >
      <ContentLimitLineStyled
        sx={{
          ...sx,
        }}
        pb={0.5}
        className={className}
      >
        {title}
      </ContentLimitLineStyled>
    </Tooltip>
  );
};

export default ContentLimitedLine;
