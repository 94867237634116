import { styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TypographyMaxRows = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'maxrows',
})(({ maxrows }) =>
  maxrows
    ? {
        display: '-webkit-inline-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: maxrows,
        WebkitBoxOrient: 'vertical',
      }
    : null
);

TypographyMaxRows.propTypes = {
  maxrows: PropTypes.number,
};

export default TypographyMaxRows;
