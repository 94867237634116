import { useState } from 'react';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import TabPanel from '@/components/products-home/TabPanel';
import { SectionWrapper } from '@/styles/styled';

import BoxTitle from '../box-title';
import ProductCart from '../product-card';

const ProductsHome = ({ data }) => {
  const { t } = useTranslation('home');
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(data?.[0]?.attributes?.detail?.[0]);
  const router = useRouter();
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    setSelectedItem(data?.[0]?.attributes?.detail?.[newValue]);
  };

  const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  return (
    <SectionWrapper sx={{ pt: { xs: 3, md: 5 } }}>
      <BoxTitle
        title={data?.[0]?.attributes?.title || ''}
        subTitle={data?.[0]?.attributes?.overview}
      />
      {!!data?.[0]?.attributes?.detail.length && (
        <Box sx={{ display: 'flex', py: { md: 5, xs: 2 } }}>
          <Grid container spacing={2}>
            {/* Left product label */}
            <Grid height="100%" item md={2} sx={{ display: { md: 'block', xs: 'none' } }}>
              <Tabs
                orientation="vertical"
                variant="standard"
                value={tabIndex}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                scrollButtons="auto"
                sx={{
                  borderLeft: 1,
                  borderColor: 'divider',
                  '& .MuiTabs-indicator': {
                    width: '4px',
                    left: 0,
                    backgroundColor: theme.palette.highlight,
                  },
                  color: theme.palette.text.primary,
                  '& .Mui-selected': {
                    color: theme.palette.text.blue,
                  },
                }}
                role="menu"
              >
                {data?.[0]?.attributes?.detail?.map((item) => (
                  <Tab
                    sx={{
                      textAlign: 'left',
                      alignItems: 'flex-start',
                      textTransform: 'inherit',
                      color: theme.palette.text.primary,
                      '&:hover': { color: theme.palette.highlight },
                    }}
                    key={item?.id}
                    label={<h3>{item?.name}</h3>}
                    {...a11yProps(item.id)}
                    role="menuitem"
                    aria-label=""
                    id={item?.id}
                  />
                ))}
                <Tab
                  sx={{
                    alignItems: 'flex-start',
                    textTransform: 'inherit',
                    opacity: 1,
                  }}
                  role="menuitem"
                  label={t('section_1_all_product')}
                  onClick={() => router.push('/dich-vu')}
                />
              </Tabs>
            </Grid>
            {/* Mobile product label */}
            <Box sx={{ display: { md: 'none', xs: 'block' }, maxWidth: { xs: 350 } }}>
              <Grid item md={2}>
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  value={tabIndex}
                  onChange={handleChange}
                  sx={{
                    borderColor: 'divider',
                    '& .MuiTabs-indicator': {
                      left: 0,
                      backgroundColor: theme.palette.text.blue,
                      margin: '0px 0px 10px',
                    },
                    color: theme.palette.text.primary,
                    '& .Mui-selected': {
                      color: theme.palette.text.blue,
                    },
                  }}
                >
                  {data?.[0]?.attributes?.detail?.map((item) => (
                    <Tab
                      sx={{
                        alignItems: 'flex-start',
                        textTransform: 'inherit',
                        color: theme.palette.text.primary,
                      }}
                      key={item?.id}
                      label={item?.name}
                      {...a11yProps(item.id)}
                    />
                  ))}
                  <Tab
                    sx={{
                      alignItems: 'flex-start',
                      textTransform: 'inherit',
                      opacity: 1,
                    }}
                    label={t('section_1_all_product')}
                    onClick={() => router.push('/dich-vu')}
                  />
                </Tabs>
              </Grid>
            </Box>
            {/* Product content */}
            <Grid item md={7} mt={{ md: 0, xs: 1 }}>
              {data?.[0]?.attributes?.detail?.map((item, index) => (
                <TabPanel key={item?.id} value={tabIndex} index={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      {selectedItem?.service_details?.length > 0 && (
                        <ProductCart
                          height={272}
                          product={selectedItem?.service_details?.[0]}
                          productIndex={0}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Grid container spacing={2}>
                        {selectedItem?.service_details?.slice(1, 5).map((service) => (
                          <Grid md={6} key={service?.id} item>
                            <ProductCart product={service} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} paddingTop="16px">
                    {selectedItem?.service_details
                      ?.slice(5, selectedItem?.service_details?.length)
                      .map((product) => (
                        <Grid md={4} flex={1} key={product?.id} item>
                          <ProductCart product={product} />
                        </Grid>
                      ))}
                  </Grid>
                </TabPanel>
              ))}
            </Grid>
            <Grid item flex={1} md={3}>
              {selectedItem?.banner?.url && (
                <Link href={selectedItem?.hyperlink || ''}>
                  <Image
                    width={0}
                    height={0}
                    alt="banner-service"
                    sizes="100vw"
                    src={selectedItem?.banner?.url}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Link>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </SectionWrapper>
  );
};

export default ProductsHome;
