import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import TypographyMaxRows from '@/components/typo-max-rows';

function ProductCart({ product, productIndex, height }) {
  const { t } = useTranslation();
  const router = useRouter();
  const isDocumentPage = router?.pathname?.includes('/document');
  const theme = useTheme();

  // check to create href of product cart in home page and dich vu page
  const hrefProduct =
    router.pathname === '/' || router.pathname.includes('/dich-vu')
      ? `/dich-vu/${product?.slug}`
      : `/document/${product?.slug}`;

  return (
    <Box
      sx={{
        position: 'relative',
        padding: { xs: '10px', md: '16px 13px' },
        borderRadius: '6px',
        width: '100%',
        height: height ?? '130px',
        background: theme.palette.background.card,
        '&:hover': {
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 4px 20px 0px',
          transition: ' 0.4s ease-in-out',
        },
      }}
    >
      <Link passHref href={hrefProduct}>
        <Box
          display="flex"
          gap={2}
          alignItems="start"
          sx={{
            '&:hover p': {
              color: theme.palette.highlight,
            },
          }}
        >
          <Image
            priority
            style={{ height: 'auto', minWidth: 58, maxWidth: 70, width: 'auto' }}
            width={0}
            height={0}
            sizes="100vw"
            alt={product?.name || ''}
            src={product?.previewImage?.url || ''}
          />

          <Box>
            <Typography
              sx={{
                mb: 2,
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '19px',
                color: theme.palette.text.blue,
                marginBottom: '5px',
                wordBreak: 'break-word',
              }}
              variant="h3"
            >
              {product?.name}
            </Typography>
            <TypographyMaxRows
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '18px',
                color: theme.palette.text.secondary_inverse,
              }}
              maxrows={product?.pin && productIndex === 0 ? 4 : 3}
            >
              {!isDocumentPage ? product?.previewContent : product?.description}
            </TypographyMaxRows>
          </Box>
        </Box>

        {productIndex === 0 && (
          <Box sx={{ position: 'absolute', bottom: '20px' }}>
            {product?.startPrice && (
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '18px',
                  lineHeight: '20px',
                  color: theme.palette.text.blue,
                  mb: 4,
                }}
              >
                {product?.startPrice}
                {/* {t('only_price', { ns: 'product' })}
                <Typography
                  ml={1}
                  color={theme.palette.text.sky}
                  component="span"
                  fontSize={16}
                  fontWeight={600}
                >
                  {`${formatToVndCurrency(smallestPriceItem?.attributes?.price)} ${
                    smallestPriceItem?.attributes?.currency
                  }/${smallestPriceItem?.attributes?.cycle}`}
                </Typography> */}
              </Typography>
            )}
            {product?.pin && (
              <Button
                style={{
                  textTransform: 'capitalize',
                  padding: '5px 20px',
                  background: theme.palette.background.button,
                  color: theme.palette.text.white,
                  borderRadius: 0,
                }}
              >
                {t('show_more', { ns: 'common' })}
              </Button>
            )}
          </Box>
        )}
      </Link>
    </Box>
  );
}

export default ProductCart;
